export const pushDataLayer = async ({ event, elementId }: Props) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
        const dataLayerObject: any = {
            event: event,
        };
        if (elementId) {
            dataLayerObject.element_id = elementId;
        }
        window.dataLayer.push(dataLayerObject);
    }
}
interface Props {
    event: 'button_clicked' | 'modal_opened' | 'modal_closed' | 'form_submitted' | 'page_view',
    elementId?: string
}