import axios from 'axios';
import Cookies from 'js-cookie';

const API_ENDPOINT = 'https://api.digipromotie.nl/gu';

const gu = async () => {
    let userID:any = Cookies.get('ui');

    if (!userID) {
        const response = await axios.post(API_ENDPOINT);
        userID = response.data.ui;

        Cookies.set('ui', userID, { expires: 365, secure: true, sameSite: 'Strict' });
    }

    return userID;
};

export default gu;
