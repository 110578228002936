import axios from 'axios';
import Cookies from 'js-cookie';
import gu from './gu';

const API_ENDPOINT = 'https://api.digipromotie.nl/te';

interface UserParams {
    fbp?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    companyName?: string;
    website?: string;
}

declare global {
    interface Window {
        Cookiebot: {
            consent: {
                marketing: boolean;
            };
        };
    }
}

function obfuscateData(data:any) {
    return btoa(data);
}

export const trackEvent = async ({event, userParams}:Props) => {

    if (typeof window !== "undefined" && window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent.marketing) {

        let userID = Cookies.get('ui');
        let fbp = Cookies.get('_fbp');
        let fullUrl = "";
        if (typeof window !== "undefined") {
            fullUrl = window.location.href;
        }

        if (!userID) {
            await gu();
            userID = Cookies.get('ui');
        }

        if (userParams && fbp) {
            userParams.fbp = fbp;
        }

        const obfuscatedPayload: { [key: string]: any } = {
            userID: userID,
            event: event,
            fullUrl: fullUrl,
            eventTimestamp: new Date().toISOString(),
        };

        if (userParams?.firstName) obfuscatedPayload.a = obfuscateData(userParams.firstName);
        if (userParams?.lastName) obfuscatedPayload.b = obfuscateData(userParams.lastName);
        if (userParams?.email) obfuscatedPayload.c = obfuscateData(userParams.email);
        if (userParams?.phone) obfuscatedPayload.d = obfuscateData(userParams.phone);
        if (userParams?.companyName) obfuscatedPayload.e = obfuscateData(userParams.companyName);
        if (userParams?.website) obfuscatedPayload.f = obfuscateData(userParams.website);

        await axios.post(API_ENDPOINT, obfuscatedPayload);
    }
};


interface Props {
    event: 'page_view' | 'lead' | 'contact'
    userParams?: UserParams
}
